<template>
  <v-container fluid>
    <v-row style="background: #2684fe">
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="2" align-self="center" class="text-center">
            <div class="similar-title text-center white--text">
              شاید
              <br />
              بپسندید
            </div>
            <v-btn large outlined color="white" class="mt-3" to="/products">
              مشاهده همه
            </v-btn>
          </v-col>
          <v-col cols="12" md="10" v-if="loading">
            <my-swiper
              :data="Array(4)"
              :maxCount="4"
              hasNavigation
              dark
              fixSpace
              :spaceBetween="10"
              :loop="false"
              class="amazing-offer-swiper"
            >
              <template #default>
                <v-skeleton-loader type="card, actions" class="rounded-0" />
              </template>
            </my-swiper>
          </v-col>
          <v-col cols="12" md="10" v-else>
            <my-swiper
              :data="products"
              :maxCount="4"
              hasNavigation
              dark
              fixSpace
              :spaceBetween="10"
              :loop="false"
              class="amazing-offer-swiper"
            >
              <template #default="{props}">
                <product-box
                  :product="props.item"
                  :name="props.item.name"
                  :url="props.item.url"
                  :img="props.item.media ? props.item.media.url : null"
                  :price="
                    props.item.offer_price
                      ? props.item.offer_price
                      : props.item.price
                  "
                  :old-price="props.item.offer_price && props.item.price"
                />
              </template>
            </my-swiper>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import MySwiper from "../MySwiper.vue";
import ProductBox from "./ProductBox.vue";
import MyAxios from "../../constants/MyAxios";
export default {
  components: { ProductBox, MySwiper },
  data: () => ({
    products: [],
    loading: true,
  }),
  mounted() {
    this.GetData();
  },
  props: {
    slug: {
      type: String,
    },
  },
  methods: {
    GetData: function() {
      if (!this.loading) this.loading = true;
      MyAxios.get("/products/similar/" + this.slug, {
        params: {
          with: "media",
        },
      })
        .then((response) => {
          this.products = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
  watch: {
    slug() {
      this.GetData();
    },
  },
};
</script>

<style scoped>
.similar-title {
  font-size: 2em;
}
</style>
