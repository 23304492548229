<template>
  <div>
    <template v-if="product">
      <share-modal ref="shareModalRef" :product="product" />
      <corrupted-file-report ref="corruptedModal" :product="product" />
    </template>
    <dialog-image v-model="videoDialog" :URL="videoURLS" />
    <div class="grey lighten-1 py-3">
      <div class="product-hint text-center py-1">
        برای باز کردن فایل های دانلود از برنامه winrar یا winzip استفاده کنین.
        (روی فایل راست کلیک کن و Extract رو بزن.)
      </div>
    </div>
    <div class="product-header white--text pa-6">
      <v-container>
        <v-row class="mx-8">
          <v-col cols="12" md="4" order="2" order-md="1" class="text-center">
            <v-img
              :src="
                images.url ? images.url : require('../../assets/img/man.jpg')
              "
              class="product-img rounded-lg elevation-2 ma-auto"
            />
            <v-rating
              color="yellow"
              dark
              :value="product.rate"
              large
              readonly
            />
          </v-col>
          <v-col cols="12" md="5" order="1" order-md="2" class="mt-1">
            <h1 class="mb-3">{{ product.name }}</h1>
            <div
              class="d-flex mt-1"
              v-for="item in product.features"
              :key="item.id"
              style="line-height: 1.5em"
            >
              <v-icon color="green lighten-1" small class="ml-2">
                mdi-checkbox-marked-circle
              </v-icon>
              {{ item.feature.title }}
            </div>
            <h2 class="mt-6" v-if="product.categories.length">دسته بندی:</h2>
            <div class="d-flex flex-wrap" v-if="product.categories.length">
              <span v-for="(item, i) in product.categories" :key="i">
                <v-chip
                  v-if="item.category"
                  class="ma-2 mr-0"
                  text-color="white"
                  label
                  color="orange"
                >
                  {{ item.category.name }}
                </v-chip>
              </span>
            </div>
            <template v-if="product.software">
              <h2 class="mt-6">نرم افزار:</h2>
              <div class="d-flex">
                <v-chip
                  class="ma-2 mr-0"
                  text-color="white"
                  label
                  :color="product.software.color || 'orange'"
                >
                  {{ product.software.name }} ({{
                    product.software.format + '.'
                  }})
                </v-chip>
              </div>
            </template>
          </v-col>
          <v-col
            cols="12"
            md="3"
            order="3"
            order-md="3"
            class="text-center my-auto"
            v-if="product.status == 1"
          >
            <my-countdown
              v-if="product.end_date_offer && !isPermanentDiscount"
              :endDate="product.end_date_offer"
            />
            <v-row v-if="product.price == '0'">
              <v-col cols="12">
                <b class="price">رایگان</b>
              </v-col>
            </v-row>
            <v-row class="mt-1" v-else>
              <template v-if="isValidDiscount">
                <v-col :md="product.offer_price ? 6 : 12" cols="12">
                  <b class="price">
                    {{
                      new Intl.NumberFormat().format(
                        product.offer_price
                          ? product.offer_price
                          : product.price
                      )
                    }}
                  </b>
                  تومان
                </v-col>
                <v-col md="6" cols="12" v-if="product.offer_price">
                  <del>
                    <b class="price">
                      {{ new Intl.NumberFormat().format(product.price) }}
                    </b>
                    تومان
                  </del>
                </v-col>
              </template>

              <v-col v-else cols="12">
                <b class="price">
                  {{ new Intl.NumberFormat().format(product.price) }}
                </b>
                تومان
              </v-col>
            </v-row>
            <template v-if="product.price == '0'">
              <v-menu offset-y v-if="product.links_media.length > 1">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    block
                    dark
                    x-large
                    color="success"
                    elevation="0"
                    class="rounded-lg"
                    :loading="loading"
                    v-bind="attrs"
                    v-on="on"
                  >
                    دانلود
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in product.links_media"
                    :key="index"
                    @click="download(item.url || item)"
                  >
                    <v-list-item-title>فایل {{ index }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn
                v-else
                block
                dark
                x-large
                color="success"
                elevation="0"
                class="rounded-lg"
                :loading="loading"
                @click="
                  product.links_media.length &&
                    download(
                      product.links_media[0].url || product.links_media[0]
                    )
                "
                v-bind="attrs"
                v-on="on"
              >
                دانلود
              </v-btn>
            </template>
            <v-btn
              v-else
              block
              dark
              x-large
              color="success"
              elevation="0"
              class="rounded-lg"
              :loading="loading"
              @click="reserve"
            >
              افزودن به سبد خرید
            </v-btn>
            <v-btn
              :disabled="!(product && product.demo)"
              block
              dark
              x-large
              color="orange darken-1"
              elevation="0"
              class="rounded-lg mt-3"
              @click="playVideo"
            >
              دموی فایل
            </v-btn>

            <v-row class="mt-2">
              <v-col cols="12" md="6" class="d-flex justify-center">
                <v-img
                  :src="require('../../assets/icons/focus.png')"
                  class="white-icon icon-small"
                />
                <div class="my-auto mr-2">
                  <span class="price">{{ product.viewed }}</span> بار
                </div>
              </v-col>
              <v-col cols="12" md="6" class="d-flex justify-center">
                <v-img
                  :src="require('../../assets/icons/download.png')"
                  class="white-icon icon-small"
                />
                <div class="my-auto mr-2">
                  <span class="price">{{ product.download_count }}</span> بار
                </div>
              </v-col>
            </v-row>

            <v-row class="mt-2" align="center">
              <v-col cols="6" class="d-flex justify-center">
                <v-btn
                  block
                  dark
                  icon
                  color="white"
                  elevation="0"
                  class="rounded-lg mt-3"
                  @click="$refs.corruptedModal.onOpen()"
                >
                  <v-img
                    :src="require('../../assets/icons/bug-report.png')"
                    class="white-icon icon-extra-small"
                  />
                  <small class="mr-1 d-md-none d-lg-inline">
                    گزارش خرابی فایل
                  </small>
                </v-btn>
              </v-col>
              <v-col cols="3" class="d-flex justify-center">
                <v-btn
                  dark
                  x-large
                  color="orange darken-1"
                  elevation="0"
                  class="rounded-lg mt-3"
                  icon
                  @click="openShareModal"
                >
                  <v-icon size="40" color="white"> mdi-share-variant </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="3" class="d-flex justify-center">
                <v-btn
                  v-if="isWish"
                  dark
                  fab
                  color="orange darken-1"
                  elevation="0"
                  class="rounded-lg mt-3"
                  icon
                  :loading="wishLoading"
                  @click="removeFromWishlist"
                >
                  <span slot="loader">
                    <v-progress-circular color="white" indeterminate />
                  </span>
                  <v-icon size="40" color="white"> mdi-heart </v-icon>
                </v-btn>
                <v-btn
                  v-else
                  dark
                  fab
                  color="orange darken-1"
                  elevation="0"
                  class="rounded-lg mt-3"
                  icon
                  :loading="wishLoading"
                  @click="addToWishlist"
                >
                  <span slot="loader">
                    <v-progress-circular color="white" indeterminate />
                  </span>
                  <v-icon size="40" color="white"> mdi-heart-outline </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import MyCountdown from '../../components/MyCountdown.vue';
import MySwiper from '../../components/MySwiper.vue';
import MyAxios from '../../constants/MyAxios';
import DialogImage from '../DialogImage.vue';
import DialogVideo from '../DialogVideo.vue';
import CorruptedFileReport from './CorruptedFileReport.vue';
import ShareModal from './SharePostModal.vue';
import { getUnixDifferenceTimeFromNow } from '@/constants/TimeFunctions';
export default {
  components: {
    MyCountdown,
    MySwiper,
    CorruptedFileReport,
    ShareModal,
    DialogVideo,
    DialogImage,
  },
  data() {
    return {
      loading: false,
      wishLoading: false,
      images: [],
      videoDialog: false,
      videoURLS: [],
    };
  },
  watch: {
    product: {
      immediate: true,
      handler(val) {
        // let images = val.images_media || [];
        // if (val.media) images.unshift(val.media);
        this.images = val.media;

        this.GetWishItem(val.id);
      },
    },
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isPermanentDiscount() {
      const result = !!Number(this.product.is_permanent_offer);
      return result;
    },
    isValidDiscount() {
      const diff = getUnixDifferenceTimeFromNow(this.product.end_date_offer);
      return (diff && diff < 0) || this.isPermanentDiscount;
    },
  },
  methods: {
    onSearchClicked() {
      this.$root.$emit('openSearchDialog');
    },
    reserve() {
      this.loading = true;
      this.$root.$emit('addToCart', this.product, () => {
        this.loading = false;
      });
    },
    download(link) {
      this.increaseDownloadCount();
      window.open(link, '_blank');
    },
    increaseDownloadCount() {
      MyAxios.post('/downloads/create', {
        user_id: this.$store.state.userData?.id || 1,
        media_id: this.product.links_media[0].id
          ? this.product.links_media[0].id
          : null,
        product_id: this.product.id,
      });
    },
    openShareModal() {
      this.$refs.shareModalRef.modal = true;
    },
    GetWishItem: function(product_id) {
      if (this.$store.state.userData) {
        if (!this.wishLoading) this.wishLoading = true;
        MyAxios.get('/wishlist/index', {
          params: {
            noPaginate: true,
            conditions: {
              product_id,
              user_id: this.$store.state.userData?.id,
            },
          },
        })
          .then(response => {
            this.isWish = response.data[0] || null;
            this.wishLoading = false;
          })
          .catch(error => {
            console.log(error);
            this.wishLoading = false;
          });
      }
    },
    addToWishlist() {
      if (!this.$store.state.userData) {
        this.$root.$emit('toast', {
          text: 'برای افزودن به علاقه مندی ها وارد حساب خود شوید.',
        });
        return;
      }
      if (!this.wishLoading) this.wishLoading = true;
      MyAxios.post('/wishlist/create', {
        product_id: this.product.id,
        user_id: this.$store.state.userData?.id,
      })
        .then(response => {
          console.log(response);
          this.isWish = response.data || null;
          this.wishLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.wishLoading = false;
        });
    },
    removeFromWishlist() {
      if (!this.$store.state.userData) {
        this.$root.$emit('toast', {
          text: 'برای افزودن به علاقه مندی ها وارد حساب خود شوید.',
        });
        return;
      }
      if (!this.wishLoading) this.wishLoading = true;
      MyAxios.post('/wishlist/delete', {
        product_id: this.product.id,
        user_id: this.$store.state.userData?.id,
      })
        .then(response => {
          console.log(response);
          this.isWish = null;
          this.wishLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.wishLoading = false;
        });
    },
    playVideo() {
      // if (this.product?.demo?.url) {
      //   this.videoURLS = [
      //     {
      //       src: this.product?.demo?.url || "",
      //       // src: item.video.url,
      //       type: "video/mp4",
      //     },
      //   ];
      //   this.videoDialog = true;
      // }
      if (this.product?.demo?.url) {
        this.videoURLS = this.product?.demo?.url || '';
        this.videoDialog = true;
      }
    },
  },
  mounted() {
    // console.log('inside product header', this.product);
  },
};
</script>

<style scoped>
.product-hint {
  position: relative;
  border-right-width: 20px;
  border-right-color: red;
  background-color: white;
  border-right-style: solid;
}
.product-img {
  /* width: 20em;
    height: 20em; */
  width: 100%;
  /* height: 100%; */
  margin: auto;
}
.product-header {
  width: 100%;
  background: url('../../assets/header.jpg');
}
.price {
  font-size: 2em;
}
</style>
