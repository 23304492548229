<template>
  <v-dialog ref="dialog" v-model="modal" width="600px">
    <v-card class="text-center rounded-lg">
      <v-card-text>
        <v-form ref="form">
          <v-row justify="center">
            <v-col md="9" cols="12">
              <h1 class="orange--text darken-1 my-8">
                گزارش خرابی فایل
              </h1>
              <v-form-base
                :row="rowAttribute"
                :col="{ cols: 12 }"
                :model="model"
                :schema="schema"
              />
              <v-btn
                block
                large
                dark
                color="success"
                :loading="loading"
                @click="submit"
              >
                ارسال گزارش شما
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { Constants } from "../../constants/GlobalConstants";
import VFormBase from "vuetify-form-base";
import MyAxios from "../../constants/MyAxios";
import { formPostPrepare } from "@/constants/PanelFormHandler";

export default {
  components: { VFormBase },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modal: false,
      loading: false,
      rules: Constants.rules,
      model: {
        user_id: this.$store.state.userData?.id,
        name: "",
        email: "",
        cellphone: "",
        // subject: "",
        desc: "",
      },
      schema: {
        name: {
          type: "text",
          solo: true,
          flat: true,
          label: "نام شما...",
          backgroundColor: "grey lighten-2",
          rules: [Constants.rules.required],
          class: "required rounded-lg",
          // col: 9,
        },
        email: {
          type: "text",
          solo: true,
          flat: true,
          label: "ایمیل شما...",
          backgroundColor: "grey lighten-2",
          rules: [Constants.rules.email],
          class: "required rounded-lg",
          // col: 9,
        },
        cellphone: {
          type: "text",
          solo: true,
          flat: true,
          label: "موبایل شما...",
          required: true,
          class: "required rounded-lg",
          backgroundColor: "grey lighten-2",
          rules: [Constants.rules.cellphone],
        },
        // subject: {
        //   type: "text",
        //   solo: true,
        //   flat: true,
        //   label: "موضوع...",
        //   backgroundColor: "grey lighten-2",
        //   rules: [Constants.rules.required],
        //   class: "required rounded-lg",
        // },
        desc: {
          type: "textarea",
          solo: true,
          flat: true,
          label: "توضیحات...",
          backgroundColor: "grey lighten-2",
          rules: [Constants.rules.required],
          class: "required rounded-lg",
        },
      },
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        // if (this.withModel) this.$emit("input", this.newpassword.value);
        // else this.PostNewPassword();
        this.loading = true;

        let formdata = formPostPrepare(this.model);
        formdata.append("product_id", this.product.id);
        // if (this.product.vendor_id)
        //   formdata.append("vendor_id", this.product.vendor_id);

        MyAxios.post("reports/product/create/" + this.product.slug, formdata)
          .then((response) => {
            this.loading = false;
            this.modal = false;
            this.$root.$emit("toast", {
              text:
                "گزارش شما با موفقیت ثبت شد. نتیجه بررسی به شمااطلاع داده خواهد شد.",
              type: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text:
                error.response.data.msg ||
                error.response.data ||
                "مشکلی پیش آمد. لطفا دوباره تلاش کنید.",
              type: "error",
            });
          });
      }
    },
    onOpen() {
      this.modal = true;
    },
    onClose() {
      this.modal = false;
    },
  },
};
</script>

<style scoped>
.ico-shadow {
  box-shadow: 0 0 0px 7px #cbcbcb, 0 0 4px 13px #dcdcdc;
}
</style>
